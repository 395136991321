<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 26rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">区域代理规则配置</div>
          <el-form-item label="计算模式">
            <el-radio-group v-model="form.award_mode">
              <el-radio :label="1">直接计算</el-radio>
              <el-radio :label="2">极差模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="乡镇代理申请条件">佣金满
            <el-input-number v-model="form.up_street_limit_fee"></el-input-number>元
          </el-form-item>
          <el-form-item label="乡镇代理分佣比例">
            <el-input-number v-model="form.street_award_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item label="区县代理申请条件">佣金满
            <el-input-number v-model="form.up_district_limit_fee"></el-input-number>元
          </el-form-item>
          <el-form-item label="区县代理分佣比例">
            <el-input-number v-model="form.district_award_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item label="市代理申请条件">佣金满
            <el-input-number v-model="form.up_city_limit_fee"></el-input-number>元
          </el-form-item>
          <el-form-item label="市代理分佣比例">
            <el-input-number v-model="form.city_award_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item label="省代理申请条件">佣金满
            <el-input-number v-model="form.up_province_limit_fee"></el-input-number>元
          </el-form-item>
          <el-form-item label="省代理分佣比例">
            <el-input-number v-model="form.province_award_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      form:{
        award_mode:1,
        street_award_scale:0,
        up_street_limit_fee:0,
        district_award_scale:0,
        up_district_limit_fee:0,
        city_award_scale:0,
        up_city_limit_fee:0,
        province_award_scale:0,
        up_province_limit_fee:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.areaAgent.rule().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.areaAgent.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>